<template>
  <div>
    <!-- Show the header option -->
    <div class="d-flex justify-end mb-6">
      <!-- Show the add button -->
      <v-btn
        depressed
        class="mr-3"
        color="primary"
        :disabled="isMakingRequest"
        @click="handleComposeClick"
      >
        <v-icon left>
          edit
        </v-icon>

        Compose
      </v-btn>

      <!-- Show the add button -->
      <v-btn
        depressed
        color="primary"
        :loading="isRefreshing"
        :disabled="isMakingRequest"
        @click="handleRefreshRequest"
      >
        <v-icon left>
          refresh
        </v-icon>

        Refresh
      </v-btn>
    </div>

    <!-- In case there are no tasks yet -->
    <template
      v-if="emails.length === 0"
    >
      <v-subheader class="d-flex justify-space-between primary white--text">
        <span>
          Emails
        </span>

        <span>
          0 messages
        </span>
      </v-subheader>

      <div
        class="white mt-0 px-4 d-flex justify-center"
      >
        <!-- Show the animation here -->
        <div style="max-width: 360px" class="mx-auto">
          <lottie-animation
            loop
            file="131552-empty.json"
          />
        </div>
      </div>
    </template>

    <!-- Otherwise -->
    <template v-else>

    </template>

    <!-- Show the create dialog here -->
    <v-dialog
      v-model="shouldShowComposeDialog"
      :persistent="isMakingRequest"
      max-width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          Compose Email
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- Message content input field -->
          <v-textarea
            v-model="formData.message"
            :hide-details="!$v.formData.message.$anyError"
            :error-messages="$v.formData.message.$anyError ? ['Please enter a valid message'] : null"
            @blur="$v.formData.message.$touch"
            :disabled="isMakingRequest"
            counter="20000"
            label="Message"
            outlined
            dense
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowComposeDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isSending"
            :disabled="isMakingRequest"
            @click="handleSubmitRequest"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the gmail connect dialog here -->
    <v-dialog
      v-model="shouldShowGmailDialog"
      :persistent="isMakingRequest"
      max-width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          Connect your Gmail
        </v-card-title>

        <v-card-text class="pt-6">
          You need to connect your gmail account with us, to be able to send emails from our dashboard.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowGmailDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingRequest"
            @click="handleConnectRequest"
          >
            Connect
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength, email } from "vuelidate/lib/validators"
import { localStorageManager, API_DATA } from "@/helpers/localStorageManager.js"

// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Define generator function
const generateForm = () => ({
  message: ""
})

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachEmails",

  // Register children components
  components: {
    LottieAnimation
  },

  // Accept incoming data from parent
  props: {
    influencer: {
      type: Object,
      required: true
    },

    data: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not the request is being made for these
    isSending: false,
    isRefreshing: false,

    // Whether or not if we're making a network request right now
    isMakingRequest: false,

    // Whether or not to show the create new email dialog
    shouldShowComposeDialog: false,

    // Whether or not to show the dialog to connect gmail
    shouldShowGmailDialog: false,

    // The form
    formData: generateForm()
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get all the emails related to this influencer
     *
     * @returns {Array}
     */
    emails() {
      return this.data.emails.filter((search) => search.campaign_report_influencer_id === this.influencer.id)
    }
  },

  // Define vuelidate validations
  validations: {
    formData: {
      message: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20000)
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Make a network request to send the email
     *
     * @returns {void}
     */
    async handleSubmitRequest() {
      // Run validations on all inputs
      await this.$v.formData.$touch()

      // If there's any error
      if (this.$v.formData.$anyError) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true
      this.isSending = true

      // Try making a network request
      try {
        // Use helper function
        const response = await axios({
          url: `/api/influencer-outreach/${this.influencer.campaign_report_id}/influencers/${this.influencer.id}/emails`,
          method: "POST",
          data: {
            message: this.formData.message
          }
        })

        // Dispatch the Vuex action
        this.$store.dispatch("influencerOutreach/createEmail", response.data)

        // Reset the form value
        this.formData = generateForm()

        // Show a toast message as well
        this.$store.dispatch("toasts/add", { text: "Sending your email..." })
      }
      // Catch any error
      catch (error) {
        // Log the error
        logger({ type: "InfluencerOutreachEmail Create Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred!" })
      }
      // Nevertheless
      finally {
        // At last, hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
        this.isSending = false
      }
    },

    /**
     * Refresh all the email database again
     *
     * @returns {void}
     */
    async handleRefreshRequest() {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true
      this.isRefreshing = true

      // Try making a network request
      try {
        // Use helper function
        const response = await axios({
          url: `/api/influencer-outreach/${this.influencer.campaign_report_id}/influencers/${this.influencer.id}/emails`,
          method: "GET"
        })

        // Dispatch the Vuex action
        this.$store.dispatch("influencerOutreach/updateEmails", response.data)
      }
      // Catch any error
      catch (error) {
        // Log the error
        logger({ type: "InfluencerOutreachEmail Refresh Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred!" })
      }
      // Nevertheless
      finally {
        // At last, hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
        this.isRefreshing = false
      }
    },

    /**
     * Check if the user can send an email, if yes, show a dialog
     *
     * @returns {void}
     */
    handleComposeClick() {
      // Check if the influencer has their email
      if (!this.influencer.email || !email(this.influencer.email)) {
        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Please submit the influencer's email address first." })

        // Stop further execution
        return
      }

      // Check if the user has their gmail connected for mails
      if (!this.$store.getters["auth/profile"].is_gmail_access_token_set) {
        // Show the dialog box to connect gmail
        this.shouldShowGmailDialog = true

        // Stop further execution
        return
      }

      // Otherwise, show the dialog for input box
      this.shouldShowComposeDialog = true
    },

    /**
     * Handle the event to connect user's gmail account with our app
     *
     * @returns {void}
     */
    handleConnectRequest() {
      // Redirect the user to this path
      window.open(`${process.env.VUE_APP_API_BASE_URL}/api/oauth/google/?token=${localStorageManager({ name: API_DATA }).access_token}&redirect=${btoa(window.location.href)}`, "_self")

      // Hide the dialog
      this.shouldShowGmailDialog = false
    }
  }
}
</script>
